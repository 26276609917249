import { render, staticRenderFns } from "./BenchmarkDetails.vue?vue&type=template&id=f0c8d57c&scoped=true&"
import script from "./BenchmarkDetails.vue?vue&type=script&lang=js&"
export * from "./BenchmarkDetails.vue?vue&type=script&lang=js&"
import style0 from "./BenchmarkDetails.vue?vue&type=style&index=0&id=f0c8d57c&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0c8d57c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2082127983/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f0c8d57c')) {
      api.createRecord('f0c8d57c', component.options)
    } else {
      api.reload('f0c8d57c', component.options)
    }
    module.hot.accept("./BenchmarkDetails.vue?vue&type=template&id=f0c8d57c&scoped=true&", function () {
      api.rerender('f0c8d57c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/vuejs/components/organization/customer-management/customer-details/technicalSectionTabs/BenchmarkDetails.vue"
export default component.exports